<template>
    <div id="blogdetail">
        <div class="main-page-wrapper">
            <!-- ===================================================
				Loading Transition
			==================================================== -->
            <section>
                <div id="preloader">
                    <div id="ctn-preloader" class="ctn-preloader">
                        <div class="animation-preloader">
                            <div class="text-center">
                                <img class="spinner" src="../../../assets/image/ingomu-preloader.gif" alt="" />
                            </div>
                            <!-- <div class="spinner"></div> -->
                            <div class="txt-loading">
                                <span data-text-preloader="i" class="letters-loading">
                                    i
                                </span>
                                <span data-text-preloader="n" class="letters-loading">
                                    n
                                </span>
                                <span data-text-preloader="g" class="letters-loading-green">
                                    g
                                </span>
                                <span data-text-preloader="o" class="letters-loading-green">
                                    o
                                </span>
                                <span data-text-preloader="m" class="letters-loading">
                                    m
                                </span>
                                <span data-text-preloader="u" class="letters-loading">
                                    u
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


            <!-- 
			=============================================
				Theme Main Menu
			============================================== 
			-->
            <SiteHeader></SiteHeader>


            <!--
			=====================================================
				Feature Blog One
			=====================================================
			-->
            <div class="blog-page-bg pt-50">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-8 feature-blog-one width-lg blog-details-post-v1">
                            <div class="post-meta">
                                <img class="image-meta" :src="lgimage" alt="" v-if="lgimage != ''" />
                                <div class="tag text-dark">{{ shortFormatDate(date) }}</div>
                                <h3 class="title">{{ title }}</h3>
                                <div class="blogBody pt-10" v-html="body"></div>
                                <BlogNewsletter></BlogNewsletter>
                                <div
                                    class="d-sm-flex align-items-center justify-content-between share-area sidebar-keyword">
                                    <div class="blog-sidebar-one">
                                        <div class="sidebar-keyword">
                                            <ul class="clearfix" v-if="tags.length > 0">
                                                <!-- <li>Tags: &nbsp;</li> -->
                                                <li v-for="(tag, i) in tags" :key="i">
                                                    <router-link :to="{ name: 'blogtags', params: { tag: tag } }">{{ tag
                                                        }}</router-link>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <!-- <ul class="share-option d-flex align-items-center">
                                        <li>Share</li>
                                        <li><a href="#" style="background: #F6616F;"><i class="fa fa-google-plus"
                                                    aria-hidden="true"></i></a></li>
                                        <li><a href="#" style="background: #41CFED;"><i class="fa fa-twitter"
                                                    aria-hidden="true"></i></a></li>
                                        <li><a href="#" style="background: #588DE7;"><i class="fa fa-facebook"
                                                    aria-hidden="true"></i></a></li>
                                    </ul> -->
                                </div>
                            </div> <!-- /.post-meta -->
                        </div>
                        <BlogSidebar></BlogSidebar>
                    </div>
                </div>
            </div> <!-- /.feature-blog-one -->

            <!--
			=====================================================
				Footer
			=====================================================
			-->
            <SiteFooter></SiteFooter>

            <!-- Modal Contact Form One -->
            <div class="modal fade modal-contact-popup-one" id="contactModal" tabindex="-1" role="dialog"
                aria-labelledby="contactModalTitle" aria-hidden="true">
                <ContactUs @clicked="closeModal"></ContactUs>
            </div>


            <!-- Scroll Top Button -->
            <button class="scroll-top">
                <i class="fa fa-angle-up" aria-hidden="true"></i>
            </button>

        </div>
    </div>
</template>
<script>
import postscribe from "postscribe";
import SiteHeader from "@/components/SiteHeader";
import SiteFooter from "@/components/SiteFooter";
import ContactUs from "@/components/ContactUs";
import formateDateMixin from "@/mixins/formatDate";
import BlogSidebar from "./BlogSidebar"
import BlogNewsletter from "../../plugins/Newsletter.vue"
import { mapActions, mapGetters } from "vuex";

export default {
    name: 'blogdetail',
    metaInfo() {
        return {
            title: this.name,
        }
    },
    components: {
        SiteHeader,
        SiteFooter,
        BlogSidebar,
        ContactUs,
        BlogNewsletter
    },
    mixins: [formateDateMixin],
    data() {
        return {
            name: '',
            title: '',
            body: '',
            lgimage: '',
            date: '',
            slug: '',
            category: '',
            tags: [],
        }
    },
    mounted() {
        let formattedStr = this.$route.params.slug.replace(/-/g, ' ');
        formattedStr = formattedStr.charAt(0).toUpperCase() + formattedStr.slice(1)
        this.name = formattedStr
        this.$store.dispatch("getBlogItem", { slug: this.$route.params.slug });
        postscribe(
            "#blogdetail",
            `<script src="https://dktoyr513tjgs.cloudfront.net/v2/vendor/mega-menu/assets/js/custom.js"><\/script>`
        );
        postscribe(
            "#blogdetail",
            `<script src="https://dktoyr513tjgs.cloudfront.net/v2/js/theme.js?v=3"><\/script>`
        );
    },
    computed: {
        ...mapGetters(["blogItem"]),
        shareTwitter() {
            let formattedTitle = this.title.replace(/\s+/g, "%20")
            return `https://twitter.com/intent/tweet?text=${formattedTitle}%20ingomu.com/${this.slug}`
        },
        shareLinkedin() {
            let formattedLink = `ingomu.com/${this.slug}`
            return `https://www.linkedin.com/shareArticle?mini=true&url=${formattedLink}`
        },
        shareFacebook() {
            let formattedLink = `ingomu.com/${this.slug}`
            return `https://www.facebook.com/sharer/sharer.php?u=${formattedLink}`
        },
        sharePinterest() {
            let formattedTitle = this.title.replace(/\s+/g, "%20")
            return `https://pinterest.com/pin/create/button/?url=${formattedTitle}&media=&description=${this.slug}`
        }
    },
    methods: {
        fetchBlogItem() {
            this.$store.dispatch("getBlogItem", { slug: this.$route.params.slug });
        },
        closeModal(value) {
			if (value) {
				this.$fire({
					title: "Success",
					text: "Thank you! Our team has received your request. Keep an eye on your inbox; we'll be in touch shortly to schedule our call. Thank you for choosing Ingomu.",
					type: "success",
				});
			}
		},
    },
    watch: {
        blogItem(newValue) {
            newValue.forEach(element => {
                this.name = element.title
                this.title = element.title
                setTimeout(() => {
                    const info = { page_path: location.pathname, page_title: element.title, page_location: location.href }
                    this.$gtag.pageview(info)
                }, 1000)
                this.body = element.body
                this.date = element.date
                this.tags = element.tags
                this.slug = element.slug
                this.category = element.category
                if (element.lgimage != null && element.lgimage != "") {
                this.lgimage = unescape(element.lgimage.substring(0, element.lgimage.indexOf("&token=")))
            } else if (element.xlimage != null && element.xlimage != "") {
                this.lgimage = unescape(element.xlimage.substring(0, element.xlimage.indexOf("&token=")))
            } else if (element.mdimage != null && element.mdimage != "") {
                this.lgimage = unescape(element.mdimage.substring(0, element.mdimage.indexOf("&token=")))
            } else if (element.smimage != null && element.smimage != "") {
                this.lgimage = unescape(element.smimage.substring(0, element.smimage.indexOf("&token=")))
            }
                // if (element.lgimage != null && element.lgimage != "") {
                //     this.lgimage = element.lgimage
                // } else if (element.xlimage != null && element.xlimage != "") {
                //     this.lgimage = element.xlimage
                // } else if (element.mdimage != null && element.mdimage != "") {
                //     this.lgimage = element.mdimage
                // } else if (element.smimage != null && element.smimage != "") {
                //     this.lgimage = element.smimage
                // }
            })
        },
        '$route': {
            handler: 'fetchBlogItem',
            immediate: true
        }
    }
}
</script>

<style scoped>
.blog-page-bg {
    background: linear-gradient(45deg, #46c346, #3b5998);
}

.feature-blog-one .post-meta,
.feature-blog-one .post-meta .image-meta {
    border-radius: 25px !important;
}

.blog-page-bg {
    background: linear-gradient(45deg, #fff, #fff);
}

.pt-50 {
    padding-top: 50px;
}
</style>
