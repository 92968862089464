<template>
			<!--
				=====================================================
					Fancy Short Banner Four
				=====================================================
				-->
                <div class="fancy-short-banner-four mt-50 mb-50" id="newsletter">
				<div class="container">
					<div class="bg-wrapper pd-25">
						<div class="row align-items-center">
							<div class="col-lg-6">
								<div class="title">
									<h6 class="text-white">ACTIONABLE TIPS FOR A BETTER LIFE</h6>
									<h2 class="fs20pt">Weekly coaching tips in your inbox</h2>
								</div> <!-- /.title-style-one -->
							</div>
							<div class="col-lg-6">
								<div class="form-wrapper">
									<form type="email" v-on:submit.prevent="submit('newsletter')" data-vv-scope="newsletter">
										<input class="form-control border-0 required email fs12pt" v-model="newsletterForm.email" name="actionemail"
                                    :class="{ 'border-red': errors.has('newsletter.email') }"
                                    placeholder="Your Email Address" type="email" v-validate="'required|email'"
                                    id="actionemail" required="required" data-error="A valid email is required." />
										<button type="submit" class="subbtn">Subscribe</button>
									</form>
									<p class="font-rubik text-white fs8pt warntext pt-20">
										By subscribing to our newsletter, you allow us to contact you at the email
										address
										provided. You may unsubscribe at any time. Your information will never be shared
										or
										sold.
									</p>
								</div> <!-- /.form-wrapper -->
							</div>
						</div>
					</div> <!-- /.bg-wrapper -->
				</div> <!-- /.container -->
			</div> <!-- /.fancy-short-banner-four -->

</template>
<script>
import { mapActions, mapGetters } from "vuex";

export default {
    name: 'newsletter',
    data() {
		return {
			newsletterForm: {
                email: ""
            },
        }
    },
    methods: {
		...mapActions(["newsletterConsumer"]),
		submit(scope) {
            var _this = this;
            this.$validator.validateAll(scope).then(function (res) {
                if (res) {
                    _this
                        .newsletterConsumer({
                            email: _this.newsletterForm.email
                        })
                        .then(() => {
                            _this.newsletterForm.email = "";
                            _this.$validator.reset();
                            _this.$fire({
                                title: "Success",
                                text: "Thank you for subscribing. Welcome to our community! Look for your first coaching tips shortly.",
                                type: "success",
                            });
                        })
                        .catch((error) => {
                            console.log(error);
                            _this.$fire({
                                title: "Error",
                                text: "Sorry, there seems to be a problem.",
                                type: "error",
                            });
                        });
                }
            });
        },
    }
}
</script>
<style scoped>
.fs8pt {
    font-size: 8pt !important;
}
.fs12pt {
    font-size: 12pt !important;
}
.fs14pt {
    font-size: 14pt !important;
}
.fs20pt {
    font-size: 20pt !important;
}
.subbtn {
    width: auto !important;
    font-size: 12pt !important;
    padding: 0 10px !important;
}
.pd-25 {
    padding: 25px !important;
}
.mt-50 {
    margin-top: 50px !important;
}
.mb-50 {
    margin-bottom: 50px !important;
}
.warntext {
    padding-top: 20px !important;
    line-height: normal !important;
}
</style>